
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

import theme from './theme';

const classNames = mergeStyleSets({
    Loading: {
        ...theme.styles.flexRow,
        padding: '32px',
        margin: '8px',
        backgroundColor: '#eeeeee'
    },
    icon: {
        fontSize: '30px',
        paddingRight: '32px'
    },
    text: {
        ...theme.fabricTheme.fonts.xxLarge
    }
});

const Loading = () => {

    return (
        <div className={classNames.Loading}>
            <Icon iconName="Settings" className={classNames.icon} />
            <div className={classNames.text}>Načítám data...</div>
        </div>
    );
}

export default Loading;
