import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

import Router from './Router';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import { getAppName } from './utils';

document.title = getAppName();

ReactDOM.render(
    <Provider store={configureStore()}>
        <Router />
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
