
import React from 'react';
import { Router } from 'react-router';

import { renderRoutes } from "react-router-config";

import history from './history';
import App from './App';
import HomePage from './components/homePage/HomePage';
import LoginPage from './components/auth/LoginPage';
import ProjectPage from './components/projectPage/ProjectPage';
import ProjectListPage from './components/projectListPage/ProjectListPage';

const routes = [
    {
        component: App,
        routes: [
            {
                path: '/',
                exact: true,
                component: HomePage
            }, {
                path: '/project/:id',
                component: ProjectPage
            }, {
                path: '/projectList',
                component: ProjectListPage
            }, {
                path: '/login',
                component: LoginPage
            }
        ]
    }
];

const MainRouter = () => {
    return (
        <Router history={history}>
            {renderRoutes(routes)}
        </Router>
    );
}

export default MainRouter;
