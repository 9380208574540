
import { combineReducers } from 'redux';

import error from '../common/client/reducers/error';
import auth from '../common/client/reducers/auth';
import app from './app';
import project from './project';
import projectList from './projectList';

export default combineReducers({
    app,
    auth,
    error,
    project: project(),
    projectList: projectList()
});
