
import {
    CLEAR_PROJECT_LIST_ACTION_TYPE,
    GET_PROJECT_LIST_ACTION_TYPE
} from '../sagas/projectList';

import { LOGIN_ACTION_TYPE } from '../common/client/sagas/auth';

import {initialState as loaderInitialState, createLoaderReducer} from '../common/client/reducers/loader';

const initialState = {
    ...loaderInitialState
};

export default function createReducer() {
    const loaderReducer = createLoaderReducer(GET_PROJECT_LIST_ACTION_TYPE);

    return (state = initialState, action) => {
        switch (action.type) {
            case LOGIN_ACTION_TYPE.SUCCESS:
                return {
                    ...initialState
                };

            case CLEAR_PROJECT_LIST_ACTION_TYPE:
                return {
                    ...initialState
                };

            default:
                return loaderReducer(state, action);
        }
    };
}
