
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';

import { PROJECT_STATUS } from '../../common/common/project/status';

import theme from '../common/theme';

import StepSpinner from './StepSpinner';
import PanelStep from './PanelStep';

const classNames = mergeStyleSets({
    params: {
        ...theme.styles.flexColumnGrow,
        paddingRight: '16px'
    }
});

const getStepStatus = (status) => {
    switch (status) {
        case PROJECT_STATUS.WAIT_FOR_PROCESS.value:
        case PROJECT_STATUS.PROCESSING.value:
        case PROJECT_STATUS.WAIT_FOR_PUBLISHING.value:
            return 1;
        case PROJECT_STATUS.INVALID_INPUT_DATA.value:
            return 2;
        default:
            return 0;
    }
}

const getBackgroundColor = (status, operationRunning) => {
    switch (status) {
        case PROJECT_STATUS.WAIT_FOR_PROCESS.value:
        case PROJECT_STATUS.PROCESSING.value:
        case PROJECT_STATUS.WAIT_FOR_PUBLISHING.value:
            return '#dff6dd';
        case PROJECT_STATUS.INVALID_INPUT_DATA.value:
            return '#fde7e9';
        default:
            return operationRunning ? '#fff4ce' : '#eeeeee';
    }
}

const PanelStepCheck = (props) => {

    const handleCheck = () => {
        if (props.onCheckInputData) {
            props.onCheckInputData();
        }
    }

    let operationRunning = false;

    const buttons = [];
    if (props.project.status === PROJECT_STATUS.WAIT_FOR_CHECK_INPUT.value) {
        operationRunning = props.operationRunning;
        buttons.push(
            <PrimaryButton
                key="check"
                text="Zkontrolovat"
                onClick={handleCheck}
                disabled={operationRunning}
            />
        );
        if (operationRunning) {
            buttons.push(<StepSpinner key="spinner" />);
        }
    }

    return (
       <PanelStep
            title="Kontrola zdrojových dat"
            buttons={buttons}
            backgroundColor={getBackgroundColor(props.project.status, operationRunning)}
            stepStatus={getStepStatus(props.project.status)}
            stepStatusValidText="Zdrojová data na zadané adrese jsou připravená pro zpracování"
            stepStatusInvalidText="Zdrojová data na zadané adrese není možné zpracovat"
       >
            <div className={classNames.params}>
                <TextField
                    label="URL adresa zdrojových dat projektu"
                    readOnly
                    defaultValue={props.project.params.inputDataUrl}
                />
            </div>
       </PanelStep>
   );
}

export default PanelStepCheck;
