
import { ERROR_CLEAR_ACTION_TYPE } from '../sagas/error';

const initialState = {
    loading: false,
    loaded: false,
    data: null,
    error: false
};

function createLoaderReducer(actionType) {
    return (state = initialState, action) => {
        switch (action.type) {
            case actionType.DEFAULT:
                return {
                    ...state,
                    data: null,
                    error: false,
                    loading: true,
                    loaded: false
                };

            case actionType.SUCCESS:
                return {
                    ...state,
                    data: action.data,
                    error: false,
                    loading: false,
                    loaded: true
                };

            case actionType.FAILED:
                return {
                    ...state,
                    error: true,
                    loading: false
                };

            case ERROR_CLEAR_ACTION_TYPE:
                return {
                    ...state,
                    error: false
                };

            default:
                return state
        }
    }
}

export {initialState, createLoaderReducer};
