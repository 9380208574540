
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';

import { PROJECT_STATUS } from '../../common/common/project/status';

import theme from '../common/theme';

import PanelStep from './PanelStep';
import StepSpinner from './StepSpinner';
import TaskListPanel from './TaskListPanel';

const classNames = mergeStyleSets({
    params: {
        ...theme.styles.flexColumnGrow,
        paddingRight: '16px'
    }
});


const getStepStatus = (status) => {
    switch (status) {
        case PROJECT_STATUS.WAIT_FOR_PUBLISHING.value:
            return 1;
        default:
            return 0;
    }
}
const getBackgroundColor = (status) => {
    switch (status) {
        case PROJECT_STATUS.PROCESSING.value:
            return '#fff4ce';
        case PROJECT_STATUS.WAIT_FOR_PUBLISHING.value:
            return '#dff6dd';
        default:
            return '#eeeeee';
    }
}

const PanelStepProcess = (props) => {

    const handleCheck = () => {
        if (props.onProcessInputData) {
            props.onProcessInputData();
        }
    }

    const buttons = [];
    if ([PROJECT_STATUS.WAIT_FOR_PROCESS.value, PROJECT_STATUS.PROCESSING.value].indexOf(props.project.status) !== -1) {
        buttons.push(
            <PrimaryButton
                key="process"
                onClick={handleCheck}
                text="Zpracovat"
                disabled={props.project.status === PROJECT_STATUS.PROCESSING.value}
            />
        );
        if (props.project.status === PROJECT_STATUS.PROCESSING.value) {
            buttons.push(<StepSpinner key="spinner" />);
        }
    }

    return (
        <PanelStep
            title="Zpracování zdrojových dat"
            buttons={buttons}
            backgroundColor={getBackgroundColor(props.project.status)}
            stepStatus={getStepStatus(props.project.status)}
            stepStatusValidText="Data jsou zpracovaná a jsou připravená pro publikování"
            stepStatusInvalidText="Při zpracování dat došlo k chybě"
        >
            <div className={classNames.params}>
                <TaskListPanel
                    tasks={props.project.tasks}
                />
            </div>
        </PanelStep>
    );
}

export default PanelStepProcess;
