
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { List } from 'office-ui-fabric-react/lib/List';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';

import theme from '../common/theme';
import createCommandItem from '../common/createCommandItem';
import PageTitle from '../common/PageTitle';

import ProjectItem from './ProjectItem';

const COMMAND_CREATE = {
    key: 'create',
    name: 'Vytvořit nový',
    iconName: 'AddTo'
};

const COMMAND_REFRESH = {
    key: 'refresh',
    name: 'Obnovit',
    iconName: 'Refresh'
};

const classNames = mergeStyleSets({
    root: {
        ...theme.styles.flexColumnGrow,
        minHeight: '0px'
    },
    list: {
        flex: '1 0',
        overflow: 'auto'
    }
});

const ProjectListTitle = (props) => {

    let commandItems = [];
    if (props.isUserLogged) {
        commandItems.push(createCommandItem(COMMAND_CREATE, props.onCreateClick));
    }
    commandItems.push(createCommandItem(COMMAND_REFRESH, props.onRefreshClick));

    return (
        <PageTitle title="Seznam Vašich projektů" >
            <CommandBar
                farItems={commandItems}
            />
        </PageTitle>
    );
}

const ProjectList = (props) => {

    const ITEM_WIDTH = 700;

    let columnCount = 1;

    const getItemCountForPage = (itemIndex, surfaceRect) => {
        if (itemIndex === 0) {
            columnCount = Math.trunc(surfaceRect.width / ITEM_WIDTH);
        }

        return props.projectList.length;
    };

    const handleClick = (project) => {
        if (project.id && props.onProjectClick) {
            props.onProjectClick(project);
        }
    }

    const onRenderCell = (project) => {
        return (
            <ProjectItem
                width={(100 / columnCount) + '%'}
                project={project}
                onClick={handleClick.bind(this, project)}
            />
        );
    };

    return (
        <div className={classNames.root}>
            <ProjectListTitle
                isUserLogged={props.isUserLogged}
                onCreateClick={props.onCreateClick}
                onRefreshClick={props.onRefreshClick}
            />
            <div className={classNames.list}>
                <List
                    items={props.projectList}
                    getItemCountForPage={getItemCountForPage}
                    onRenderCell={onRenderCell}
                />
            </div>
        </div>
    );
}

export default ProjectList;
