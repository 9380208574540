
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mergeStyleSets } from '@uifabric/merge-styles';

import { PROJECT_STATUS } from '../../common/common/project/status';

import {
    DELETE_PROJECT_ACTION_TYPE,
    GET_PROJECT_ACTION_TYPE,
    CHECK_INPUT_DATA_ACTION_TYPE,
    REFRESH_PROJECT_ACTION_TYPE,
    PROCESS_INPUT_DATA_ACTION_TYPE,
    PUBLISH_ACTION_TYPE,
    SET_PROJECT_PUBLIC_ACTION_TYPE
} from '../../sagas/project';

import theme from '../common/theme';
import DialogConfirm from '../common/DialogConfirm';
import Loading from '../common/Loading';
import ContentNavigation from '../common/ContentNavigation';
import LoginInfo from '../auth/LoginInfo';

import Project from './Project';

const classNames = mergeStyleSets({
    root: {
        ...theme.styles.flexColumnGrow,
        minHeight: 0
    },
    root2: {
        ...theme.styles.flexColumnGrow,
        alignItems: 'center',
        justifyContent: 'center'
    },
    contentNavigation: {
        width: '800px',
        paddingBottom: '8px',
        marginBottom: '16px',
        borderBottom: '1px solid #cccccc'
    },
    title: {
        ...theme.styles.flexColumn,
        minHeight: 0,
        fontSize: '32px',
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 0px 24px 0px'
    },
    content: {
        ...theme.styles.flexColumnGrow,
        minHeight: 0,
        width: '800px'
    },
    footer: {
        padding: '4px'
    }
});

class ProjectPage extends Component {

    componentDidMount() {
        this.checkFetchProject();
        this.checkAutorefresh();
    }

    componentDidUpdate(prevProps, prevState) {
        this.checkFetchProject();
        this.checkAutorefresh();
    }

    checkFetchProject() {
        const notLoaded = (!this.props.project.loaded && !this.props.project.loading && !this.props.authLoading);
        const anotherId = (this.props.project.loaded && (this.props.project.data.project.id != this.props.id)); // eslint-disable-line eqeqeq
        const error = (this.props.project.error)
        if ((notLoaded || anotherId) && (!error))  {
            this.props.getProject(this.props.token, this.props.id);
        }
    }

    checkAutorefresh() {
        if (this.props.project.loaded) {
            let canRunAutorefresh = (this.props.project.data.project.status === PROJECT_STATUS.PROCESSING.value)

            if (canRunAutorefresh) {
                // ToDo: clear timeout on leave page
                setTimeout(() => {
                    this.props.refreshProject(this.props.token, this.props.id);
                }, 10 * 1000);
            }
        }
    }

    getIsUserLogged() {
        return (!!this.props.token);
    }

    getLoggedUserName() {
        return this.props.loggedUser
            ? this.props.loggedUser.username
            : null;
    }

    getNavigationItems() {
        return [
            {title: 'Úvodní strana', path: '#/'},
            {title: 'Seznam projektů', path: '#/projectList'},
            {title: 'Projekt', path: `#/project/${this.props.id}`}
        ];
    }

    getProjectTitle() {
        return (this.props.project.loaded)
            ? this.props.project.data.project.title
            : 'Projekt';
    }

    handleDeleteClick = async() => {
        const message = `Opravdu chcete smazat projekt "${this.props.project.data.project.title}"? Data budou nenávratně smazána.`;

        let confirm = await DialogConfirm.show(message);
        if (confirm) {
            this.props.deleteProject(this.props.token, this.props.id);
        }
    }

    handleCheckInputData = () => {
        this.props.checkInputData(this.props.token, this.props.id);
    }

    handleProcessInputData = () => {
        this.props.processInputData(this.props.token, this.props.id);
    }

    handleRefreshProject = () => {
        this.props.refreshProject(this.props.token, this.props.id);
    }

    handlePublish = () => {
        this.props.publish(this.props.token, this.props.id);
    }

    handleSetProjectPublic = async() => {
        const message = `Opravdu chcete nastavit projekt "${this.props.project.data.project.title}" dostupný pro všechny uživatele?`;

        let confirm = await DialogConfirm.show(message);
        if (confirm) {
            this.props.setProjectPublic(this.props.token, this.props.id);
        }
    }

    render() {
        return (
            <div className={classNames.root}>
                <LoginInfo />
                <div className={classNames.root2}>
                    <div className={classNames.contentNavigation}>
                        <ContentNavigation
                            items={this.getNavigationItems()}
                            paths={[]}
                        />
                    </div>
                    <div className={classNames.content}>
                        {(this.props.project.loaded)
                            ? <Project
                                project={this.props.project.data.project}
                                isUserLogged={this.getIsUserLogged()}
                                loggedUserName={this.getLoggedUserName()}
                                operationRunning={this.props.operationRunning}
                                onDeleteClick={this.handleDeleteClick}
                                onCheckInputData={this.handleCheckInputData}
                                onProcessInputData={this.handleProcessInputData}
                                onPublish={this.handlePublish}
                                onRefreshProject={this.handleRefreshProject}
                                onSetPublicClick={this.handleSetProjectPublic}
                            />
                            : <Loading />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteProject: (token, id) => dispatch({type: DELETE_PROJECT_ACTION_TYPE.DEFAULT, token, id}),
        checkInputData: (token, id) => dispatch({type: CHECK_INPUT_DATA_ACTION_TYPE.DEFAULT, token, id}),
        getProject: (token, id) => dispatch({type: GET_PROJECT_ACTION_TYPE.DEFAULT, token, id}),
        processInputData: (token, id) => dispatch({type: PROCESS_INPUT_DATA_ACTION_TYPE.DEFAULT, token, id}),
        publish: (token, id) => dispatch({type: PUBLISH_ACTION_TYPE.DEFAULT, token, id}),
        setProjectPublic: (token, id) => dispatch({type: SET_PROJECT_PUBLIC_ACTION_TYPE.DEFAULT, token, id}),
        refreshProject: (token, id) => dispatch({type: REFRESH_PROJECT_ACTION_TYPE, token, id})
    };
}

function mapStateToProps(state, ownProps) {
    return {
        id: ownProps.match.params.id,
        project: state.project,
        operationRunning: state.error.operationRunning,
        token: state.auth.data ? state.auth.data.user.token : null,
        loggedUser: state.auth.data ? state.auth.data.user : null,
        authLoading: state.auth.loading
    };
}

const ConnectedProjectPage = connect(mapStateToProps, mapDispatchToProps)(ProjectPage);

export default ConnectedProjectPage;
