
import React from 'react';
import { Layer } from 'office-ui-fabric-react/lib/Layer';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { mergeStyleSets } from '@uifabric/merge-styles';

const ErrorLayer = (props) => {
    const classNames = mergeStyleSets({
        root: {
            backgroundColor: '#ffffff'
        }
    });

    return (
        <Layer>
            <div className={classNames.root}>
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    onDismiss={() => {
                        if (props.clearError) {
                            props.clearError();
                        }
                    }}
                    dismissButtonAriaLabel="Zavřít"
                >
                    Při zpracování požadavku došlo k chybě.
                </MessageBar>
            </div>
        </Layer>
    );
}

const InfoLayer = (props) => {
    const classNames = mergeStyleSets({
        root: {
            backgroundColor: 'rgba(255,255,255,0)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            paddingTop: '4px'
        },
        info: {
            width: '400px',
            padding: '8px',
            backgroundColor: '#dff6dd',
            textAlign: 'center'
        }
    });

    return (
        <Layer>
            <div className={classNames.root}>
                <div className={classNames.info}>{props.operation} bylo dokončeno.</div>
            </div>
        </Layer>
    );
}

const Error = (props) => {
    let component = null;
    if (props.error.operationSuccess) {
        component = (
            <InfoLayer
                operation={props.error.operationSuccess}
                clearSuccess={props.clearSuccess}
            />
        );
    }
    if (props.error.errors.length > 0) {
        component = <ErrorLayer clearError={props.clearError} />;
    }
    return component;
}

export default Error;
