
import React from 'react';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyleSets } from '@uifabric/merge-styles';

const ContentNavigationItemSeparator = () => {
    const classNames = mergeStyleSets({
        root: {
            fontSize: '14px',
            fontWeight: 'bold',
            paddingTop: '4px',
            paddingLeft: '4px',
            paddingRight: '4px',
            color: '#777777'
        }
    });

    return (
        <div className={classNames.root}>
            <Icon iconName="ChevronRightSmall"/>
        </div>
    );
}

const ContentNavigationItemLink = ({title, path}) => {
    const classNames = mergeStyleSets({
        root: {
            fontSize: '17px'
        }
    });

    return <Link className={`ms-font-l ${classNames.root}`} href={path}>{title}</Link>;
}

const ContentNavigationItemText = ({title}) => {
    const classNames = mergeStyleSets({
        root: {
            fontWeight: 'bold'
        }
    });

    return (
        <div
            className={`ms-fontSize-l ms-fontWeight-semibold ${classNames.root}`}>{title}
        </div>
    );
}

const ContentNavigationItem = ({item, first, last, paths}) => {
    const classNames = mergeStyleSets({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }
    });

    let path = paths[item.id]
        ? paths[item.id]
        : item.path;

    return (
        <div className={classNames.root}>
            {(!first) ? <ContentNavigationItemSeparator /> : null}
            {(last)
                ? <ContentNavigationItemText title={item.title} />
                : <ContentNavigationItemLink title={item.title} path={path} />
            }
        </div>
    );
}

const ContentNavigation = (props) => {
    const classNames = mergeStyleSets({
        root: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: '12px'
        }
    });

    return (
        <div className={classNames.root}>
            {props.items.map((item, index) => {
                return (
                    <ContentNavigationItem
                        key={index}
                        item={item}
                        first={index === 0}
                        last={index === (props.items.length - 1)}
                        paths={props.paths}
                    />
                );
            })}
        </div>
    );
}

export default ContentNavigation;
