
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

const classNames = mergeStyleSets({
    spinner: {
        paddingTop: '8px'
    }
});

const StepSpinner = (props) => {

    return (
        <Spinner
            className={classNames.spinner}
            size={SpinnerSize.medium}
        />
    );
}

export default StepSpinner;
