
import { getTheme } from 'office-ui-fabric-react/lib/Styling';

const fabricTheme = getTheme();

fabricTheme.palette.selected = 'rgba(255,128,0,0.2)';
fabricTheme.palette.bgDefault = fabricTheme.palette.neutralLighter;
fabricTheme.palette.bgHover = fabricTheme.palette.themeLighter;
fabricTheme.palette.bgActive = fabricTheme.palette.themeLight;

const BACKGROUND_URL = 'assets/background.jpg';

export default {
    styles: {
        background: {
            background: `linear-gradient(rgba(0,0,0,0.0),rgba(0,0,0,0.0)),url("${BACKGROUND_URL}") center no-repeat`,
            backgroundSize: 'cover'
        },
        flexColumn: {
            display: 'flex',
            flexDirection: 'column'
        },
        flexColumnGrow: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0'
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row'
        },
        flexRowGrow: {
            display: 'flex',
            flexDirection: 'row',
            flex: '1 0'
        },
        title1: {
            ...fabricTheme.fonts.xxLarge,
            paddingBottom: fabricTheme.spacing.l2
        },
        title2: {
            ...fabricTheme.fonts.xLarge,
            paddingBottom: fabricTheme.spacing.l1
        }
    },
    fabricTheme
};
