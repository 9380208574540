
function fetch(url, options) {
    // ToDo: deprecated
    return get(url, options);
}

function get(url, options) {
    return doFetch(url, {
        method: 'GET',
        headers: {
            'Authorization': (options && options.token) ? `Token ${options.token}` : ''
        }
    });
}

function post(url, data, options) {
    return doFetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': (options && options.token) ? `Token ${options.token}` : ''
        },
        body: JSON.stringify(data)
    });
}

function doFetch(url, options) {
    return window.fetch(url, options)
        .then(response => {
            if (response.status === 200) {
                return response.json().then(
                    data => {
                        return data;
                    }
                );
            } else {
                return Promise.reject(response);
            }
        });
}

module.exports = {
    fetch,
    get,
    post
};
