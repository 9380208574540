
import {
    GET_PROJECT_ACTION_TYPE,
    SET_PROJECT_STATUS_ACTION_TYPE
} from '../sagas/project';

import { LOGIN_ACTION_TYPE } from '../common/client/sagas/auth';

import {initialState as loaderInitialState, createLoaderReducer} from '../common/client/reducers/loader';

const initialState = {
    ...loaderInitialState
};

export default function createReducer() {
    const loaderReducer = createLoaderReducer(GET_PROJECT_ACTION_TYPE);

    return (state = initialState, action) => {
        switch (action.type) {
            case LOGIN_ACTION_TYPE.SUCCESS:
                return {
                    ...initialState
                };

            case SET_PROJECT_STATUS_ACTION_TYPE:
                let newState = {
                    ...state
                };
                newState.data.project.status = action.status;
                return newState;

            default:
                return loaderReducer(state, action);
        }
    };
}
