
import React, { Component } from 'react';
import { render } from 'react-dom';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

let resolve;

const classNames = mergeStyleSets({
    warning: {
        paddingLeft: '8px',
        color: 'red'
    }
});


const Warning = (props) => {
    return (
        <div className={classNames.warning}>{props.message}</div>
    );
}

const Warnings = (props) => {
    return (
        <div>
            {props.warnings.map((item, index) => {
                return <Warning key={index} message={item} />;
            })}
        </div>
    );
}

class DialogCreateProject extends Component {

    static create() {
        const containerElement = document.createElement('div');
        document.body.appendChild(containerElement);
        return render(<DialogCreateProject/>, containerElement);
    }

    constructor(props) {
        super(props);
        this.state = {
            hidden: !props.show,
            title: '',
            url: '',
            warnings: []
        };
    }

    checkValues() {
        let warnings = [];
        if (!this.state.title) {
            warnings.push('Není zadaný název projektu');
        }
        if (!this.state.url) {
            warnings.push('Není zadaná URL adresa zdrojových dat projektu');
        }
        this.setState({warnings: warnings});
        return (warnings.length === 0);
    }

    handleCancel = () => {
        this.setState({hidden: true});
        resolve(null);
    }

    handleConfirm = () => {
        if (this.checkValues()) {
            this.setState({hidden: true});
            resolve({
                title: this.state.title,
                url: this.state.url
            });
        }
    }

    handleOnChangeTitle = (event) => {
        this.setState({title: event.target.value});
    }

    handleOnChangeUrl = (event) => {
        this.setState({url: event.target.value});
    }

    render () {
        return (
            <Dialog
                hidden={this.state.hidden}
                minWidth={500}
                onDismiss={this.handleCancel}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Vytvoření nového projektu"
                }}
                modalProps={{
                    isBlocking: false
                }}
                >
                <div>
                    <TextField
                        label="Název projektu"
                        value={this.state.title}
                        onChange={this.handleOnChangeTitle}
                        onKeyDown={this.handleOnKeyDown}
                    />
                    <TextField
                        label="URL adresa zdrojových dat projektu"
                        value={this.state.url}
                        onChange={this.handleOnChangeUrl}
                        onKeyDown={this.handleOnKeyDown}
                    />
                    <Warnings warnings={this.state.warnings} />
                </div>
                <DialogFooter>
                    <PrimaryButton onClick={this.handleConfirm} text="Vytvořit" />
                    <DefaultButton onClick={this.handleCancel} text="Zrušit" />
                </DialogFooter>
            </Dialog>
        );
    }

    show() {
        this.setState({
            hidden: false,
            title: '',
            url: '',
            warnings: []
        });
        return new Promise((res) => {
            resolve = res;
        });
    }
}

export default DialogCreateProject.create();
