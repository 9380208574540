
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';

import { PROJECT_STATUS } from '../../common/common/project/status';

import theme from '../common/theme';

import StepSpinner from './StepSpinner';
import PanelStep from './PanelStep';

const classNames = mergeStyleSets({
    content: {
        ...theme.styles.flexColumnGrow,
        paddingRight: '16px'
    }
});

const getBackgroundColor = (status, operationRunning) => {
    return operationRunning ? '#fff4ce' : '#eeeeee';
}

const PanelStepPublish = (props) => {

    const handlePublish = () => {
        if (props.onPublish) {
            props.onPublish();
        }
    }

    let operationRunning = false;

    const buttons = [];
    if (props.project.status === PROJECT_STATUS.WAIT_FOR_PUBLISHING.value) {
        operationRunning = props.operationRunning;
        buttons.push(
            <PrimaryButton
                key="publish"
                text="Publikovat"
                onClick={handlePublish}
                disabled={operationRunning}
            />
        );
        if (operationRunning) {
            buttons.push(<StepSpinner key="spinner" />);
        }
    }

    return (
       <PanelStep
            title="Publikace virtuální prohlídky"
            buttons={buttons}
            backgroundColor={getBackgroundColor(props.project.status, operationRunning)}
       >
            <div className={classNames.content}>
                <Toggle
                    label="Publikovat virtuální prohlídku pro počítač"
                    onText="Ano"
                    offText="Ne"
                    inlineLabel
                    defaultChecked
                />
                <Toggle
                    label="Publikovat virtuální prohlídku pro mobilní zařízení"
                    onText="Ano"
                    offText="Ne"
                    disabled
                    inlineLabel
                />
            </div>
       </PanelStep>
   );
}

export default PanelStepPublish;
