
import { connect } from 'react-redux';

import {
    ERROR_CLEAR_ACTION_TYPE,
    ERROR_OPERATION_SUCCESS_ACTION_TYPE
} from '../sagas/error';
import Error from '../components/Error';

function mapDispatchToProps(dispatch) {
    return {
        clearError: () => dispatch({type: ERROR_CLEAR_ACTION_TYPE}),
        clearSuccess: () => dispatch({type: ERROR_OPERATION_SUCCESS_ACTION_TYPE, operation: null})
    };
}

function mapStateToProps(state) {
    return {
        error: state.error
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Error);
