
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { CommandButton } from 'office-ui-fabric-react';

import { getTitle } from '../../common/common/project/status';

import ProjectDetail from './ProjectDetail';
import theme from '../common/theme';

const classNames = mergeStyleSets({
    root: {
    },
    info: {
        ...theme.styles.flexRow
    },
    valueList: {
        ...theme.styles.flexColumnGrow
    },
    divider: {
        marginTop: '24px',
        borderBottom: '1px solid #cccccc',
        marginBottom: '24px'
    }
});

const ValueItem = (props) => {
    const classNames = mergeStyleSets({
        root: {
            display: 'flex',
            flexDirection: 'row'
        },
        title: {
            width: '150px',
            fontSize: '17px'
        },
        value: {
            fontSize: '17px',
            fontWeight: 'bold'
        }
    });
    return (
        <div className={classNames.root}>
            <div className={classNames.title}>{`${props.title}:`}</div>
            <div className={classNames.value}>{`${props.value}`}</div>
        </div>
    );
}

const ValueList = (props) => {
    return (
        <div className={props.className}>
            {props.values.map(item => {
                return (
                    <ValueItem
                        key={item.id}
                        title={item.title}
                        value={item.value}
                    />
                );
            })}
        </div>
    );
}

const Project = (props) => {

    let items = [];
    if (props.isUserLogged) {
        items.push({
            key: 'delete',
            text: 'Smazat projekt',
            iconProps: {iconName: 'Delete'},
            disabled: (props.loggedUserName !== props.project.owner),
            onClick: props.onDeleteClick
        });
        items.push({
            key: 'setProjectPublic',
            text: 'Nastavit jako veřejný',
            disabled: (props.loggedUserName !== props.project.owner),
            iconProps: {iconName: 'Group'},
            onClick: props.onSetPublicClick
        });
    }

    return (
        <div className={classNames.root}>
            <div className={classNames.info}>
                <ValueList
                    className={classNames.valueList}
                    values={[
                        {id: 'title', title: 'Název projektu', value: props.project.title},
                        {id: 'status', title: 'Stav projektu', value: getTitle(props.project.status)}
                    ]}
                />
                <CommandButton
                    iconProps={{iconName: 'TabletSelected'}}
                    text="Nástroje"
                    disabled={items.length === 0}
                    menuProps={{
                        items: items
                    }}
                />
            </div>
            <div className={classNames.divider} />
            <ProjectDetail
                project={props.project}
                operationRunning={props.operationRunning}
                onCheckInputData={props.onCheckInputData}
                onProcessInputData={props.onProcessInputData}
                onPublish={props.onPublish}
                onRefreshProject={props.onRefreshProject}
            />
        </div>
    );
}

export default Project;
