
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mergeStyleSets } from '@uifabric/merge-styles';

import { GET_PARAMS_ACTION_TYPE } from '../../sagas/app';
import { CREATE_PROJECT_ACTION_TYPE } from '../../sagas/project';
import {
    CLEAR_PROJECT_LIST_ACTION_TYPE,
    GET_PROJECT_LIST_ACTION_TYPE
} from '../../sagas/projectList';

import theme from '../common/theme';
import Loading from '../common/Loading';
import ContentNavigation from '../common/ContentNavigation';
import LoginInfo from '../auth/LoginInfo';

import ProjectList from './ProjectList';
import DialogCreateProject from '../DialogCreateProject';

const classNames = mergeStyleSets({
    root: {
        ...theme.styles.flexColumnGrow,
        minHeight: 0
    },
    root2: {
        ...theme.styles.flexColumnGrow,
        alignItems: 'center',
        justifyContent: 'center'
    },
    contentNavigation: {
        width: '800px',
        paddingBottom: '8px',
        marginBottom: '8px',
        borderBottom: '1px solid #cccccc'
    },
    title: {
        ...theme.styles.flexColumn,
        minHeight: 0,
        fontSize: '32px',
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 0px 24px 0px'
    },
    content: {
        ...theme.styles.flexColumnGrow,
        minHeight: 0,
        width: '800px'
    }
});

class ProjectListPage extends Component {

    componentDidMount() {
        this.props.clearProjectList();
        this.checkFetchParams();
        this.checkFetchProjectList();
    }

    componentDidUpdate(prevProps, prevState) {
        this.checkFetchProjectList();
    }

    checkFetchParams() {
        if (!this.props.params) {
            this.props.getParams();
        }
    }

    checkFetchProjectList() {
        const notLoaded = (!this.props.projectList.loaded && !this.props.projectList.loading && !this.props.authLoading);
        const error = (this.props.projectList.error)
        if (notLoaded && !error)  {
            this.props.getProjectList(this.props.token);
        }
    }

    getIsUserLogged() {
        return (!!this.props.token);
    }

    getNavigationItems() {
        return [
            {title: 'Úvodní strana', path: '#/'},
            {title: 'Seznam projektů', path: '#/projectList'}
        ];
    }

    handleCreateClick = async () => {
        let projectParams = await DialogCreateProject.show();
        if (projectParams) {
            this.props.createProject(this.props.token, projectParams);
        }
    }

    handleProjectClick = (project) => {
        this.props.history.push(`/project/${project.id}`);
    }

    handleRefreshClick = () => {
        this.props.getProjectList(this.props.token);
    }

    render() {
        return (
            <div className={classNames.root}>
                <LoginInfo />
                <div className={classNames.root2}>
                    <div className={classNames.contentNavigation}>
                        <ContentNavigation
                            items={this.getNavigationItems()}
                            paths={[]}
                        />
                    </div>
                    <div className={classNames.content}>
                        {(this.props.params && this.props.projectList.loaded)
                            ? <ProjectList
                                projectList={this.props.projectList.data.projectList}
                                isUserLogged={this.getIsUserLogged()}
                                onCreateClick={this.handleCreateClick}
                                onProjectClick={this.handleProjectClick}
                                onRefreshClick={this.handleRefreshClick}
                            />
                            : <Loading />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clearProjectList: (token) => dispatch({type: CLEAR_PROJECT_LIST_ACTION_TYPE, token}),
        createProject: (token, params) => dispatch({type: CREATE_PROJECT_ACTION_TYPE.DEFAULT, token, params}),
        getProjectList: (token) => dispatch({type: GET_PROJECT_LIST_ACTION_TYPE.DEFAULT, token}),
        getParams: () => dispatch({type: GET_PARAMS_ACTION_TYPE.DEFAULT})
    };
}

function mapStateToProps(state, ownProps) {
    return {
        params: state.app.params,
        projectList: state.projectList,
        token: state.auth.data ? state.auth.data.user.token : null,
        authLoading: state.auth.loading
    };
}

const ConnectedProjectListPage = connect(mapStateToProps, mapDispatchToProps)(ProjectListPage);

export default ConnectedProjectListPage;
