
import { all } from 'redux-saga/effects';

import { watchGetProject } from './project';
import { watchGetProjectList } from './projectList';
import { watchGetParams } from './app';
import { authFlow } from '../common/client/sagas/auth';

import history from '../history';

export default function* rootSagas() {
    yield all([
        watchGetProject(),
        watchGetProjectList(),
        watchGetParams(),
        authFlow({
            history: history,
            afterLogoutUrl: '/'
        })
    ]);
}
