
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';

import theme from '../common/theme';
import TaskPanel from './TaskPanel';

const classNames = mergeStyleSets({
    root: {
        ...theme.styles.flexColumn
    }
});

const TaskListPanel = (props) => {

    return (
        <div className={classNames.root}>
            {props.tasks.map(item => {
                return (
                    <TaskPanel
                        key={item.name}
                        task={item}
                    />
                );
            })}
        </div>
    );
}

export default TaskListPanel;
