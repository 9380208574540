
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { mergeStyleSets } from '@uifabric/merge-styles';

import theme from '../common/theme';

import LoginForm from '../../common/client/containers/LoginForm';

export class LoginPage extends Component {

    classNames = mergeStyleSets({
        root: {
            ...theme.styles.flexColumnGrow,
            alignItems: 'center'
        },
        panel: {
            backgroundColor: '#eeeeee',
            marginTop: '128px',
            padding: '8px',
            border: '1px solid #999999'
        },
        title: {
            fontSize: '32px'
        }
    })

    render() {
        return (
            <div className={this.classNames.root}>
                <div className={this.classNames.panel}>
                    <div className={this.classNames.title}>Přihlášení</div>
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    renderContent() {
        if (this.props.logged) {
            return <Redirect to="/" />
        }
        return (
            <LoginForm />
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
    };
}

function mapStateToProps(state) {
    return {
        logged: (state.auth.data  && state.auth.data.user)
    };
}

const ConnectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);

export default ConnectedLoginPage;
