
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';

import { getTitle } from '../../common/common/project/status';

import theme from '../common/theme';
import ValueList from '../ValueList';

const ProjectItem = (props) => {
    const classNames = mergeStyleSets({
        title: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#333333',
            paddingBottom: '4px'
        },
        container: {
            width: props.width,
            position: 'relative',
            float: 'left'
        },
        item: {
            backgroundColor: theme.fabricTheme.palette.bgDefault,
            cursor: 'pointer',
            height: '90px',
            margin: '8px',
            padding: '8px',
            selectors: {
                ':hover': {
                    backgroundColor: theme.fabricTheme.palette.bgHover
                }
            }
        }
    });

    return (
        <div className={classNames.container}>
            <div className={classNames.item} onClick={props.onClick}>
                <div className={classNames.title}>
                    {props.project.title}
                </div>
                <ValueList
                    values={[
                        {
                            id: 'status',
                            title: 'Stav projektu',
                            titleStyle: {width: '150px'},
                            value: getTitle(props.project.status)
                        }, {
                            id: 'description',
                            title: 'Popis',
                            titleStyle: {width: '150px'},
                            value: props.project.params.description || '<bez popisu>'
                        }
                    ]}
                />
            </div>
        </div>
    );
}

export default ProjectItem;
