
import React from 'react';
import { isArray } from 'lodash';
import { mergeStyleSets } from '@uifabric/merge-styles';

import theme from '../common/theme';

const classNames = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'row'
    },
    title: {
        flex: '1 0',
        ...theme.fabricTheme.fonts.large
    },
    tools: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: '8px'
    },
    tool: {
        paddingLeft: '4px'
    }
});

const Tools = (props) => {
    const children = isArray(props.tools)
        ? props.tools
        : [props.tools];
    return children.map((item, index) => {
        return (
            <div className={classNames.tool} key={index}>{item}</div>
        );
    });
}

const PageTitle = (props) => {
    return (
        <div className={classNames.root}>
            <div className={classNames.title}>{props.title}</div>
            <div className={classNames.tools}>
                <Tools tools={props.children} />
            </div>
        </div>
    );
}

export default PageTitle;
