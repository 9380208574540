
const packageJson = require('../package.json');

export function getVersionString() {
    return packageJson.version;
}

export function getAppName() {
    return `Virtuální památky`;
}

export function getAppNameWithVersion() {
    return `${getAppName()} v${getVersionString()}`;
}
