
import {
    ERROR_CREATE_ACTION_TYPE,
    ERROR_CLEAR_ACTION_TYPE,
    ERROR_OPERATION_START_ACTION_TYPE,
    ERROR_OPERATION_FAILED_ACTION_TYPE,
    ERROR_OPERATION_SUCCESS_ACTION_TYPE
} from '../sagas/error';

const initialState = {
    errors: [],
    operationSuccess: null
};

export default function app(state = initialState, action) {
    switch (action.type) {
        case ERROR_CREATE_ACTION_TYPE:
            return {
                ...state,
                errors: [
                    ...state.errors,
                    action.error.toString()
                ]
            };

        case ERROR_CLEAR_ACTION_TYPE:
            return {
                ...state,
                errors: []
            };

        case ERROR_OPERATION_START_ACTION_TYPE:
            return {
                ...state,
                operationRunning: true
            };

        case ERROR_OPERATION_FAILED_ACTION_TYPE:
            return {
                ...state,
                operationRunning: false
            };

        case ERROR_OPERATION_SUCCESS_ACTION_TYPE:
            return {
                ...state,
                operationSuccess: action.operation,
                operationRunning: false
            };

        default:
            return state
    }
}
