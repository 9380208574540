
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'office-ui-fabric-react/lib/Link';

import { LOGOUT_ACTION_TYPE } from '../../common/client/sagas/auth';

const LogoutLink = (props) => {

    const onClick = () => {
        if (props.token) {
            props.logout(props.token);
        }
    }

    return (
        <Link onClick={onClick}>Odhlásit</Link>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        logout: (token) => dispatch({type: LOGOUT_ACTION_TYPE.DEFAULT, token})
    };
}

function mapStateToProps(state) {
    return {
        token: state.auth.data ? state.auth.data.user.token : null
    };
}

const ConnectedLogoutLink = connect(mapStateToProps, mapDispatchToProps)(LogoutLink);

export default ConnectedLogoutLink;
