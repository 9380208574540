
import {
    LOGIN_ACTION_TYPE, 
    LOGOUT_ACTION_TYPE
} from '../sagas/auth';

const initialState = {
    data: null,
    loading: false,
    loginFailed: false
};

export default function app(state = initialState, action) {
    switch (action.type) {
        case LOGIN_ACTION_TYPE.DEFAULT:
            return {
                ...state,
                data: null,
                loading: true,
                loginFailed: false
            };

        case LOGIN_ACTION_TYPE.SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data
            };

        case LOGIN_ACTION_TYPE.FAILED:
            return {
                ...state,
                data: null,
                loading: false,
                loginFailed: true
            };

        case LOGOUT_ACTION_TYPE.SUCCESS:
            return {
                ...initialState
            };

        default:
            return state
    }
}
