
import React from 'react';

import { PROJECT_STATUS } from '../../common/common/project/status';

import PanelStepCheck from './PanelStepCheck';
import PanelStepProcess from './PanelStepProcess';
import PanelStepPublish from './PanelStepPublish';
import PanelStepPublic from './PanelStepPublic';

const PANEL_STATUSES_CHECK = [
    PROJECT_STATUS.WAIT_FOR_CHECK_INPUT,
    PROJECT_STATUS.INVALID_INPUT_DATA,
    PROJECT_STATUS.WAIT_FOR_PROCESS,
    PROJECT_STATUS.PROCESSING,
    PROJECT_STATUS.WAIT_FOR_PUBLISHING
];

const PANEL_STATUSES_PROCESS = [
    PROJECT_STATUS.WAIT_FOR_PROCESS,
    PROJECT_STATUS.PROCESSING,
    PROJECT_STATUS.WAIT_FOR_PUBLISHING
];

const PANEL_STATUSES_PUBLISH = [
    PROJECT_STATUS.WAIT_FOR_PUBLISHING
];

const PANEL_STATUSES_PUBLIC = [
    PROJECT_STATUS.PUBLISHED
];

function isStatusRequired(status, requiredStatus) {
    return requiredStatus.reduce((previous, current) => {
        return (current.value === status)
            ? true
            : previous;
    }, false);
}

const ProjectDetail = (props) => {
    let panels = [];

    if (isStatusRequired(props.project.status, PANEL_STATUSES_CHECK)) {
        panels.push(
            <PanelStepCheck
                key="check"
                project={props.project}
                operationRunning={props.operationRunning}
                onCheckInputData={props.onCheckInputData}
            />
        );
    }

    if (isStatusRequired(props.project.status, PANEL_STATUSES_PROCESS)) {
        panels.push(
            <PanelStepProcess
                key="process"
                project={props.project}
                onProcessInputData={props.onProcessInputData}
            />
        );
    }

    if (isStatusRequired(props.project.status, PANEL_STATUSES_PUBLISH)) {
        panels.push(
            <PanelStepPublish
                key="publish"
                project={props.project}
                operationRunning={props.operationRunning}
                onPublish={props.onPublish}
            />
        );
    }

    if (isStatusRequired(props.project.status, PANEL_STATUSES_PUBLIC)) {
        panels.push(
            <PanelStepPublic
                key="public"
                project={props.project}
            />
        );
    }

    return (
        <div>
            {panels}
        </div>
    );
}

export default ProjectDetail;
