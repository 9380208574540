
import React, { Component } from 'react';
import { render } from 'react-dom';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';

let resolve;

/**
 * Usage:
 *  1) async/await variant
 *
 *      let confirm = await DialogConfirm.show('Question');
 *      if (confirm) {
 *          // do action
 *      }
 *
 *  2) promise variant
 *
 *       DialogConfirm.show('Question').then(confirm => {
 *           if (confirm) {
 *               // do action
 *           }
 *       });
 */
class DialogConfirm extends Component {

    static create() {
        const containerElement = document.createElement('div');
        document.body.appendChild(containerElement);
        return render(<DialogConfirm/>, containerElement);
    }

    constructor(props) {
        super(props);
        this.state = {
            hidden: !props.show,
            message: ''
        };
    }

    handleCancel = () => {
        this.setState({hidden: true});
        resolve(false);
    }

    handleConfirm = () => {
        this.setState({hidden: true});
        resolve(true);
    }

    render () {
        return (
            <Dialog
                hidden={this.state.hidden}
                onDismiss={this.handleCancel}
                dialogContentProps={{
                    type: DialogType.normal
                }}
                modalProps={{
                    isBlocking: false
                }}
                >
                <div>{this.state.message}</div>
                <DialogFooter>
                    <DefaultButton onClick={this.handleConfirm} text="Ano" />
                    <PrimaryButton onClick={this.handleCancel} text="Ne" />
                </DialogFooter>
            </Dialog>
        );
    }

    show(message) {
        this.setState({
            hidden: false,
            message: message
        });
        return new Promise((res) => {
            resolve = res;
        });
    }
}

export default DialogConfirm.create();
