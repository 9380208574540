
import {
    GET_PARAMS_ACTION_TYPE
} from '../sagas/app';

const initialState = {
    params: null
};

export default function app(state = initialState, action) {
    switch (action.type) {
        case GET_PARAMS_ACTION_TYPE.SUCCESS:
            return {
                ...state,
                params: action.params.reduce((previous, current) => {
                    return {
                        ...previous,
                        [current.name]: current.value
                    };
                }, {})
            };

        default:
            return state
    }
}
