
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import theme from './common/theme';

const classNames = mergeStyleSets({
    item: {
        ...theme.styles.flexRowGrow,
        paddingBottom: '4px'
    },
    title: {
        width: '80px',
        fontSize: '14px'
    },
    value: {
        ...theme.styles.flexRowGrow,
        fontSize: '14px'
    }
});

const ValueItem = (props) => {

    let value = props.item.value;
    if (props.item.type === 'link') {
        value = (
            <a target="app" href={props.item.value}>{props.item.value}</a>
        );
    }

    return (
        <div className={classNames.item}>
            <div className={classNames.title} style={props.item.titleStyle}>
                {`${props.item.title}:`}
            </div>
            <div className={classNames.value} style={props.item.valueStyle}>
                {value}
            </div>
        </div>
    );
}

const ValueList = (props) => {

    return (
        <div className={props.className}>
            {props.values.map(item => {
                return (
                    <ValueItem
                        key={item.id}
                        item={item}
                    />
                );
            })}
        </div>
    );
}

export default ValueList;
