
import { all, call, put, takeEvery } from 'redux-saga/effects'
import history from '../history'

import { PROJECT_STATUS } from '../common/common/project/status';

import API from '../common/client/api';

import {
    createActionTypes,
    clearOperationSuccess
} from '../common/client/sagas/common';

import {
    ERROR_CLEAR_ACTION_TYPE,
    ERROR_CREATE_ACTION_TYPE ,
    ERROR_OPERATION_START_ACTION_TYPE,
    ERROR_OPERATION_SUCCESS_ACTION_TYPE
} from '../common/client/sagas/error';

export const GET_PROJECT_ACTION_TYPE = createActionTypes('project/GET_PROJECT');
export const CREATE_PROJECT_ACTION_TYPE = createActionTypes('project/CREATE_PROJECT');
export const DELETE_PROJECT_ACTION_TYPE = createActionTypes('project/DELETE_PROJECT', 'Smazání projektu');
export const CHECK_INPUT_DATA_ACTION_TYPE = createActionTypes('project/CHECK_INPUT_DATA');
export const PROCESS_INPUT_DATA_ACTION_TYPE = createActionTypes('project/PROCESS_INPUT_DATA');
export const PUBLISH_ACTION_TYPE = createActionTypes('project/PUBLISH');
export const SET_PROJECT_PUBLIC_ACTION_TYPE = createActionTypes('project/SET_PROJECT_PUBLIC', 'Nastavení projektu jako veřejný');

export const REFRESH_PROJECT_ACTION_TYPE = 'project/REFRESH_PROJECT';
export const SET_PROJECT_STATUS_ACTION_TYPE = 'project/SET_PROJECT_STATUS';

function createProject(action) {
    return API.post(
        '/api/project/createProject',
        {params: action.params},
        {token: action.token}
    );
}

function deleteProject(action) {
    return API.post(
        '/api/project/deleteProject',
        {id: action.id},
        {token: action.token}
    );
}

function checkInputData(action) {
    return API.post(
        '/api/project/checkInputData',
        {id: action.id},
        {token: action.token}
    );
}

function processInputData(action) {
    return API.post(
        '/api/project/processInputData',
        {id: action.id},
        {token: action.token}
    );
}

function publish(action) {
    return API.post(
        '/api/project/publish',
        {id: action.id},
        {token: action.token}
    );
}

function setProjectPublic(action) {
    return API.post(
        '/api/project/setProjectPublic',
        {id: action.id},
        {token: action.token}
    );
}

function getProject(action) {
    return API.fetch(`/api/project/getProject/${action.id}`, {token: action.token});
}

function* workCreateProject(action) {
    try {
        yield put({type: ERROR_CLEAR_ACTION_TYPE});
        const data = yield call(createProject, action);
        yield put({type: CREATE_PROJECT_ACTION_TYPE.SUCCESS, data: data});
        yield call(history.push, `project/${data.created.id}`);
    } catch (error) {
        yield put({type: CREATE_PROJECT_ACTION_TYPE.FAILED, error});
        yield put({type: ERROR_CREATE_ACTION_TYPE, error});
    }
}

function* workDeleteProject(action) {
    try {
        yield put({type: ERROR_CLEAR_ACTION_TYPE});
        const data = yield call(deleteProject, action);
        yield put({type: DELETE_PROJECT_ACTION_TYPE.SUCCESS, data: data});
        yield put({type: ERROR_OPERATION_SUCCESS_ACTION_TYPE, operation: DELETE_PROJECT_ACTION_TYPE.title});
        yield call(history.push, '/projectList');
        yield call(clearOperationSuccess);
    } catch (error) {
        yield put({type: DELETE_PROJECT_ACTION_TYPE.FAILED, error});
        yield put({type: ERROR_CREATE_ACTION_TYPE, error});
    }
}

function* workCheckInputData(action) {
    try {
        yield put({type: ERROR_CLEAR_ACTION_TYPE});
        yield put({type: ERROR_OPERATION_START_ACTION_TYPE});
        const data = yield call(checkInputData, action);
        yield put({type: CHECK_INPUT_DATA_ACTION_TYPE.SUCCESS, data: data});
        yield call(clearOperationSuccess);
        yield put({type: REFRESH_PROJECT_ACTION_TYPE, token: action.token, id: action.id});
    } catch (error) {
        yield put({type: CHECK_INPUT_DATA_ACTION_TYPE.FAILED, error});
        yield put({type: ERROR_CREATE_ACTION_TYPE, error});
    }
}

function* workProcessInputData(action) {
    try {
        yield put({type: ERROR_CLEAR_ACTION_TYPE});
        yield call(processInputData, action);
        yield put({type: SET_PROJECT_STATUS_ACTION_TYPE, status: PROJECT_STATUS.PROCESSING.value});
    } catch (error) {
        yield put({type: ERROR_CREATE_ACTION_TYPE, error});
    }
}

function* workPublish(action) {
    try {
        yield put({type: ERROR_CLEAR_ACTION_TYPE});
        yield put({type: ERROR_OPERATION_START_ACTION_TYPE});
        yield call(publish, action);
        yield put({type: SET_PROJECT_STATUS_ACTION_TYPE, status: PROJECT_STATUS.PUBLISHED.value});
        yield call(clearOperationSuccess);
    } catch (error) {
        yield put({type: ERROR_CREATE_ACTION_TYPE, error});
    }
}

function* workSetProjectPublic(action) {
    try {
        yield put({type: ERROR_CLEAR_ACTION_TYPE});
        const data = yield call(setProjectPublic, action);
        yield put({type: SET_PROJECT_PUBLIC_ACTION_TYPE.SUCCESS, data: data});
        yield put({type: ERROR_OPERATION_SUCCESS_ACTION_TYPE, operation: SET_PROJECT_PUBLIC_ACTION_TYPE.title});
        yield call(clearOperationSuccess);
    } catch (error) {
        yield put({type: ERROR_CREATE_ACTION_TYPE, error});
    }
}

function* workGetProject(action) {
    try {
        yield put({type: ERROR_CLEAR_ACTION_TYPE});
        const data = yield call(getProject, action);
        yield put({type: GET_PROJECT_ACTION_TYPE.SUCCESS, data: data});
    } catch (error) {
        yield put({type: GET_PROJECT_ACTION_TYPE.FAILED, error});
        yield put({type: ERROR_CREATE_ACTION_TYPE, error});
    }
}

function* workRefreshProject(action) {
    try {
        const data = yield call(getProject, action);
        yield put({type: GET_PROJECT_ACTION_TYPE.SUCCESS, data: data});
    } catch (error) {
        // nop;
    }
}

export function* watchGetProject() {
    yield all([
        takeEvery(CREATE_PROJECT_ACTION_TYPE.DEFAULT, workCreateProject),
        takeEvery(DELETE_PROJECT_ACTION_TYPE.DEFAULT, workDeleteProject),
        takeEvery(CHECK_INPUT_DATA_ACTION_TYPE.DEFAULT, workCheckInputData),
        takeEvery(GET_PROJECT_ACTION_TYPE.DEFAULT, workGetProject),
        takeEvery(PROCESS_INPUT_DATA_ACTION_TYPE.DEFAULT, workProcessInputData),
        takeEvery(PUBLISH_ACTION_TYPE.DEFAULT, workPublish),
        takeEvery(SET_PROJECT_PUBLIC_ACTION_TYPE.DEFAULT, workSetProjectPublic),
        takeEvery(REFRESH_PROJECT_ACTION_TYPE, workRefreshProject)
    ]);
}