
import { delay, put } from 'redux-saga/effects'

import { ERROR_OPERATION_SUCCESS_ACTION_TYPE } from './error';

export function createActionTypes(baseType, title) {
    return {
        DEFAULT: baseType,
        SUCCESS: `${baseType}_SUCCESS`,
        FAILED:  `${baseType}_FAILED`,
        title: title || baseType
    };
}

export function* clearOperationSuccess() {
    yield delay(3000);
    yield put({type: ERROR_OPERATION_SUCCESS_ACTION_TYPE, operation: null});
}
