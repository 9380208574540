
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import theme from '../common/theme';

const classNames = mergeStyleSets({
    root: {
        ...theme.styles.flexColumn,
        backgroundColor: '#eeeeee',
        marginTop: '16px',
        padding: '16px',
        marginBottom: '16px'
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#777777',
        paddingBottom: '8px'
    },
    content: {
        ...theme.styles.flexRowGrow
    },
    buttons: {
        ...theme.styles.flexColumn,
        paddingLeft: '16px',
        width: '150px',
        alignItems: 'center'
    },
    statusContainer: {
        ...theme.styles.flexRow,
        paddingTop: '16px',
        alignItems: 'center'
    },
    statusValid: {
    },
    statusInvalid: {
        color: 'red'
    },
    icon: {
        fontSize: 24,
        paddingLeft: '8px',
        paddingRight: '16px'
    }
});

const StepStatus = (props) => {
    switch (props.status) {
        case 1:
            return (
                <div className={classNames.statusContainer}>
                    <FontIcon iconName="CheckMark" className={classNames.icon} />
                    <div className={classNames.statusValid}>{props.validText}</div>
                </div>
            );
        case 2:
            return (
                <div className={classNames.statusContainer}>
                    <FontIcon iconName="IncidentTriangle" className={classNames.icon} />
                    <div className={classNames.statusInvalid}>{props.invalidText}</div>
                </div>
            );
        default:
            return null;
    }
}

const PanelStep = (props) => {
    return (
        <div className={classNames.root} style={{backgroundColor: props.backgroundColor}}>
            <div className={classNames.title}>{props.title}</div>
            <div className={classNames.content}>
                {props.children}
                <div className={classNames.buttons}>
                    {props.buttons}
                </div>
            </div>
            <StepStatus
                status={props.stepStatus}
                validText={props.stepStatusValidText}
                invalidText={props.stepStatusInvalidText}
            />
        </div>
    );
}

export default PanelStep;
