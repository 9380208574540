
import React, { Component } from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { Link } from 'office-ui-fabric-react/lib/Link';

import theme from '../common/theme';
import LoginInfo from '../auth/LoginInfo';

import { getVersionString } from '../../utils';

const classNames = mergeStyleSets({
    root: {
        ...theme.styles.flexColumnGrow,
        ...theme.styles.background,
        minHeight: 0
    },
    root2: {
        ...theme.styles.flexColumnGrow,
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px'
    },
    appTitle: {
        ...theme.styles.flexColumn,
        minHeight: 0,
        fontSize: '68px',
        fontWeight: 'bold',
        //height: '300px',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        paddingBottom: '68px'
    },
    appTitle2: {
        fontSize: '32px',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingBottom: '32px'
    },
    content: {
        ...theme.styles.flexColumnGrow,
        maxWidth: '700px',
    },
    description: {
        padding: '12px',
        paddingBottom: '32px',
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'justify'
    },
    p0: {
        paddingBottom: '8px'
    },
    p1: {
        width: '150px',
        position: 'relative',
        float: 'left',
        paddingLeft: '16px'
    },
    p2: {
        paddingLeft: '16px'
    },
    projectsLinkContainer: {
        ...theme.styles.flexRow,
        justifyContent: 'flex-end',
        padding: '24px 0px 0px 0px'
    },
    projectsLink: {
        padding: '12px',
        fontSize: '20px',
        fontWeight: 'bold'
    }
});

const DESCRIPTION_1 =
    `SW pro integraci podkladů a tvorbu virtuální prohlídky památkového objektu. Z GIS dat, ` +
    `panoramatických snímků, dat laserového skenování, fotogrammetrických dat a popisných informací ` +
    `k jednotlivým detailům památkového objektu SW vytvoří virtuální prohlídku ve webovém prohlížeči.`;


const HomePageContent = (props) => {
    return (
        <div>
            <div className={classNames.description}>
                {DESCRIPTION_1}
            </div>
            <div className={classNames.description}>
                <div className={classNames.p0}>Software je výsledkem projektu NAKI II</div>
                <div>
                    <div className={classNames.p1}>Název projektu:</div>
                    <div className={classNames.p2}>Kulturní dědictví krajiny Arcidiecéze olomoucké</div>
                </div>
                <div>
                    <div className={classNames.p1}>Číslo projektu:</div>
                    <div className={classNames.p2}>DG16P02B014</div>
                </div>
            </div>
            <div className={classNames.description}>
                <div className={classNames.p0}>Řešitelský tým</div>
                <div className={classNames.p2}>Ing. Martin Vlk, Ing. Pavel Sedlář, Doc. Ing. Ivo Machar, PhD.</div>
            </div>
            <div className={classNames.projectsLinkContainer}>
                <Link className={classNames.projectsLink} href={`#/projectList`} >Přejít na seznam projektů</Link>
            </div>
        </div>
    );
}

class HomePage extends Component {

    componentDidMount() {
        this.checkFetch();
    }

    componentDidUpdate(prevProps, prevState) {
        this.checkFetch();
    }

    checkFetch() {
        /*
        if ((!this.props.apps.appList) && (!this.props.apps.loading) && (this.props.auth.data)) {
            this.props.getAppList(this.props.auth.data.user.token);
        }
        */
    }

    render() {
        return (
            <div className={classNames.root}>
                <LoginInfo />
                <div className={classNames.root2}>
                    <div className={classNames.appTitle}>
                        <div>Tvorba virtuální prohlídky</div>
                        <div>památkového objektu</div>
                    </div>
                    <div className={classNames.appTitle2}>Softwarový vzdělávací portál kulturního dědictví v krajině Arcidiecéze olomoucké</div>
                    <div className={classNames.content}>
                        {this.props.loading
                            ? <div>Nahrávám...</div>
                            : <HomePageContent auth={this.props.auth} />
                        }
                    </div>
                    <div className="ms-font-m">Verze: {getVersionString()}</div>
                </div>
            </div>
        );
    }
}

export default HomePage;
