
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator'
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import theme from '../common/theme';

const classNames = mergeStyleSets({
    root: {
        ...theme.styles.flexRow
    },
    progress: {
        ...theme.styles.flexRowGrow
    },
    progressInactive: {
        flex: '1 0'
    },
    progressActive: {
        flex: '1 0',
        fontWeight: 'bold'
    },
    status: {
        ...theme.styles.flexRow,
        alignItems: 'center',
        paddingLeft: '32px'
    },
    icon: {
        fontSize: 24
    }
});

const TaskStatus = (props) => {
    if (props.result === 2) {
        return <FontIcon iconName="CheckMark" className={classNames.icon} />
    }
    if (props.result === 3) {
        return <FontIcon iconName="Cancel" className={classNames.icon} />
    }
    return <FontIcon iconName="" className={classNames.icon} />
}

const TaskPanel = (props) => {

    const progressClassName = (props.task.result === 1)
        ? classNames.progressActive
        : classNames.progressInactive;

    return (
        <div className={classNames.root}>
            <div className={classNames.progress}>
                <ProgressIndicator
                    className={progressClassName}
                    label={props.task.title}
                    percentComplete={props.task.progress / 100}
                    barHeight={12}
                />
            </div>
            <div className={classNames.status}>
                <TaskStatus result={props.task.result} />
            </div>
        </div>
    );
}

export default TaskPanel;
