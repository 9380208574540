
import { connect } from 'react-redux';

import { LOGIN_ACTION_TYPE } from '../sagas/auth';
import LoginForm from '../components/LoginForm';

function mapDispatchToProps(dispatch) {
    return {
        login: (username, password) => dispatch({type: LOGIN_ACTION_TYPE.DEFAULT, username, password})
    };
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
