
const PROJECT_STATUS = {
    WAIT_FOR_CHECK_INPUT: {value: 1, title: 'Čeká na kontrolu vstupních dat'},
    CHECKING_INPUT: {value: 2, title: 'Probíhá kontrola vstupních dat'},
    INVALID_INPUT_DATA: {value: 3, title: 'Vstupní data neodpovídají požadavkům'},
    WAIT_FOR_PROCESS: {value: 4, title: 'Čeká na zpracování'},
    PROCESSING: {value: 5, title: 'Probíhá zpracování'},
    WAIT_FOR_PUBLISHING: {value: 6, title: 'Čeká na vypublikování virtuální prohlídky'},
    PUBLISHED: {value: 7, title: 'Vypublikovaná virtuální prohlídka'},
};

function getTitle(value) {
    value = parseInt(value);
    let title = '';
    for (let statusName in PROJECT_STATUS) {
        let status = PROJECT_STATUS[statusName];
        if (status.value === value) {
            title = status.title;
        }
    }
    return title;
}

module.exports = {
    PROJECT_STATUS,
    getTitle: getTitle
};
