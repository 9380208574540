
import React from 'react';
import { connect } from 'react-redux';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { Link } from 'office-ui-fabric-react/lib/Link';

import theme from '../common/theme';
import LogoutLink from './LogoutLink';

const classNames = mergeStyleSets({
    root: {
        ...theme.styles.flexRow,
        flexDirection: 'row-reverse',
        padding: '4px'
    },
    rowContent: {

    },
    loggedUserInfo: {
        ...theme.styles.flexRow
    },
    username: {
        paddingRight: '4px'
    }
});

const LoggedUserInfo = (props) => {
    return (
        <div className={classNames.loggedUserInfo}>
            <div className={classNames.username}>{props.username}</div>
            <LogoutLink/>
        </div>
    );
}

const LoginInfoContent = (props) => {
    if (props.username) {
        return <LoggedUserInfo username={props.username} />;
    } else {
        if (!props.loading) {
            return <Link href="#/login">Přihlásit</Link>;
        } else {
            return <div>...</div>
        }
    }
}

const LoginInfo = (props) => {
    return (
        <div className={classNames.root}>
            <div className={classNames.rowContent}>
                <LoginInfoContent
                    username={props.username}
                    loading={props.loading}
                />
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        loading: state.auth.loading,
        username: state.auth.data ? state.auth.data.user.username : null
    };
}

const ConnectedLoginInfo = connect(mapStateToProps)(LoginInfo);

export default ConnectedLoginInfo;
