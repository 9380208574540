
import React from 'react';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { renderRoutes } from "react-router-config";

import Error from './common/client/containers/Error';

initializeIcons();

const App = ({route}) => {

    const classNames = mergeStyleSets({
        root: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column'
        }
    })

    return (
        <Fabric className={classNames.root}>
            {renderRoutes(route.routes)}
            <Error />
        </Fabric>
    );
}

export default App;
