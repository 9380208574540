
import { call, put, take } from 'redux-saga/effects'
import URI from 'urijs';

import API from '../api';
import { createActionTypes } from './common';

export const LOGIN_ACTION_TYPE = createActionTypes('auth/LOGIN');
export const LOGOUT_ACTION_TYPE = createActionTypes('auth/LOGOUT');

const LOCAL_STORAGE_KEY_TOKEN = 'auth_token';

function* getToken() {
    let token = (window && window.location) ? getTokenWindowLocation() : null;
    if (!token) {
        token = yield call([localStorage, 'getItem'], LOCAL_STORAGE_KEY_TOKEN);
        if (token) {
            console.log('Token from localStorage:', token);
        }
    }
    return token;
}

function getTokenWindowLocation() {
    let uri = new URI(window.location.href);
    let token = uri.search(true).token;
    if (token) {
        console.log('Token from URL:', token);
        uri.removeSearch('token');
        window.history.pushState('', '', uri.toString());
    }
    return token;
}

function login(action) {
    return API.post(
        '/api/users/login', {
            user: {
                username: action.username,
                password: action.password
            }
        }
    );
}

function logout(action) {
    return API.post('/api/users/logout', {}, {token: action.token});
}

function verify(token) {
    return API.get('/api/users/verify', {token: token});
}

export function* authFlow(options) {
    let token = yield call(getToken);
    while (true) {
        try {
            let authData = null;
            if (token) {
                yield put({type: LOGIN_ACTION_TYPE.DEFAULT});
                authData = yield call(verify, token);
            } else {
                const loginAction = yield take(LOGIN_ACTION_TYPE.DEFAULT)
                authData = yield call(login, loginAction);
            }
            yield call([localStorage, 'setItem'], LOCAL_STORAGE_KEY_TOKEN, authData.user.token);
            yield put({type: LOGIN_ACTION_TYPE.SUCCESS, data: authData});

            const logoutAction = yield take(LOGOUT_ACTION_TYPE.DEFAULT)
            try {
                const data = yield call(logout, logoutAction);
                yield call([localStorage, 'removeItem'], LOCAL_STORAGE_KEY_TOKEN);
                yield put({type: LOGOUT_ACTION_TYPE.SUCCESS, data: data});
                if (options && options.history && options.afterLogoutUrl) {
                    yield call(options.history.push, options.afterLogoutUrl);
                }
            } catch (error) {
                yield put({type: LOGOUT_ACTION_TYPE.FAILED, error});
            }

        } catch (error) {
            yield put({type: LOGIN_ACTION_TYPE.FAILED, error});
        }

        token = null;
    }
}
