
import React from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';

import theme from '../common/theme';

import PanelStep from './PanelStep';
import ValueList from '../ValueList';

const classNames = mergeStyleSets({
    content: {
        ...theme.styles.flexColumnGrow,
        paddingRight: '16px'
    },
    app: {
        padding: '8px'
    },
    appList: {

    }
});

const getUrl = (appUrl, apiUrl, projectId, url) => {
    if (appUrl && apiUrl && projectId) {
        return `${appUrl}?url=${apiUrl}project/getMscAppProject/${projectId}`;
    } else {
        return url;
    }
}

const ApplicationPanel = (props) => {
    let url = getUrl(props.appUrl, props.apiUrl, props.projectId, props.url);

    return (
        <div className={classNames.app}>
            <ValueList
                values={[
                    {id: 'title', title: 'Název', value: props.title, valueStyle: {fontWeight: 'bold'}},
                    {id: 'description', title: 'Popis', value: props.description},
                    {id: 'url', title: 'URL', value: url, type: 'link'},
                ]}
            />
        </div>
    );
}

const ApplicationListPanel = (props) => {
    return (
        <div className={classNames.appList}>
            {props.items.map((item, index) => {
                return (
                    <ApplicationPanel
                        key={index}
                        title={item.title}
                        description={item.description}
                        url={item.url}
                        appUrl={props.appUrl}
                        apiUrl={props.apiUrl}
                        projectId={props.projectId}
                    />
                );
            })}
        </div>
    )
}

const PanelStepPublic = (props) => {

    const buttons = [];

    let applications = [];
    let appUrl = '';
    let apiUrl = '';

    try {
        let params = JSON.parse(props.project.params.inputParams);
        applications = params.applications || [];
        appUrl = params.appUrl || '';
        apiUrl = params.apiUrl || '';
    }
    catch (e) {
        console.log(e);
    }

    return (
       <PanelStep
            title="Publikované virtuální prohlídky"
            buttons={buttons}
       >
            <div className={classNames.content}>
                <ApplicationListPanel
                    items={applications}
                    appUrl={appUrl}
                    apiUrl={apiUrl}
                    projectId={props.project.id}
                />
            </div>
       </PanelStep>
   );
}

export default PanelStepPublic;
