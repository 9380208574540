
import { call, put, takeEvery } from 'redux-saga/effects'

import API from '../common/client/api';
import { createActionTypes } from '../common/client/sagas/common';
import { ERROR_CLEAR_ACTION_TYPE, ERROR_CREATE_ACTION_TYPE } from '../common/client/sagas/error';

export const CLEAR_PROJECT_LIST_ACTION_TYPE = 'project/CLEAR_PROJECT_LIST';
export const GET_PROJECT_LIST_ACTION_TYPE = createActionTypes('project/GET_PROJECT_LIST');

function getProjectList(action) {
    return API.fetch('/api/project/getProjectList', {token: action.token});
}

function* workGetProjectList(action) {
    try {
        yield put({type: ERROR_CLEAR_ACTION_TYPE});
        const data = yield call(getProjectList, action);
        yield put({type: GET_PROJECT_LIST_ACTION_TYPE.SUCCESS, data: data});
    } catch (error) {
        yield put({type: GET_PROJECT_LIST_ACTION_TYPE.FAILED, error});
        yield put({type: ERROR_CREATE_ACTION_TYPE, error});
    }
}

export function* watchGetProjectList() {
    yield takeEvery(GET_PROJECT_LIST_ACTION_TYPE.DEFAULT, workGetProjectList)
}