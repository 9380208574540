
import React, { Component } from 'react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';

const LoginMessage = (props) => {
    const classNames = mergeStyleSets({
        root: {
            paddingTop: '8px',
            paddingBottom: '8px'
        }
    });
    return (
        <div className={classNames.root}>{props.text || '\u00A0'}</div>
    );
}

const LoginInfo = (props) => {
    let text = null
    if (props.loginFailed) {
        text = 'Neplatné uživatelské jméno nebo heslo';
    }
    if (props.loading) {
        text = 'Probíhá přihlašování...';
    }
    return (
        <LoginMessage
            text={text}
        />
    );
}

class LoginForm extends Component {

    state = {
        username: '',
        password: ''
    };

    classNames = mergeStyleSets({
        root: {
            backgroundColor: '#eeeeee',
            width: '400px',
            padding: '16px'
        }
    })

    handleOnClick = (event) => {
        this.props.login(this.state.username, this.state.password);
    }

    handleOnChangePassword = (event) => {
        this.setState({password: event.target.value});
    }

    handleOnChangeUserName = (event) => {
        this.setState({username: event.target.value});
    }

    handleOnKeyDown = (event) => {
        if (event.keyCode === 13) {
            this.props.login(this.state.username, this.state.password);
        }
    }

    render() {
        return (
            <div className={this.classNames.root}>
                <TextField
                    label="Uživatelské jméno"
                    disabled={this.props.auth.loading}
                    onChange={this.handleOnChangeUserName}
                    onKeyDown={this.handleOnKeyDown}
                />
                <TextField
                    label="Heslo"
                    type="password"
                    disabled={this.props.auth.loading}
                    onChange={this.handleOnChangePassword}
                    onKeyDown={this.handleOnKeyDown}
                />
                <LoginInfo
                    loginFailed={this.props.auth.loginFailed}
                    loading={this.props.auth.loading}
                />
                <PrimaryButton
                    text="Přihlásit"
                    disabled={this.props.auth.loading}
                    onClick={this.handleOnClick}
                />
            </div>
        );
    }
}

export default LoginForm;
