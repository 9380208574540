
import { call, put, takeEvery } from 'redux-saga/effects'

import { createActionTypes } from '../common/client/sagas/common';
import { ERROR_CLEAR_ACTION_TYPE, ERROR_CREATE_ACTION_TYPE } from '../common/client/sagas/error';

export const GET_PARAMS_ACTION_TYPE = createActionTypes('app/GET_PARAMS');

function getParams() {
    return fetch('/api/app/getParams')
        .then(response => response.json());
}

function* workGetParams() {
    try {
        yield put({type: ERROR_CLEAR_ACTION_TYPE});
        const data = yield call(getParams);
        yield put({type: GET_PARAMS_ACTION_TYPE.SUCCESS, params: data.params});
    } catch (error) {
        yield put({type: GET_PARAMS_ACTION_TYPE.FAILED, error});
        yield put({type: ERROR_CREATE_ACTION_TYPE, error});
    }
}

export function* watchGetParams() {
    yield takeEvery(GET_PARAMS_ACTION_TYPE.DEFAULT, workGetParams)
}