
export default function createCommandItem(commandParams, onClick) {
    return {
        key: commandParams.key,
        name: commandParams.name,
        iconProps: {
            iconName: commandParams.iconName
        },
        onClick: onClick
    };
}
